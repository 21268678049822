export function customScripts() {
	
	var isMobile = false;

	function checkMobile() {
		if($(window).innerWidth() < 767 ) isMobile = true;
		else isMobile = false;
	}

	function videoFadeIn(){
		$(".video-content").css( 'opacity', '1' );
	}

	setTimeout( FormController, 200 );
	setTimeout( videoFadeIn, 200 );

	$(window).resize(function() {

		var wasMobile = isMobile;

		checkMobile();

		if(!isMobile && wasMobile) {
			closeMobileNav();
			
		}

		setInfographic();
		
		// if ($(window).innerWidth() > 767) {
		// 	$("nav").removeClass("navbar-fixed-top");
		// } else if ($(window).innerWidth() < 767) {
		// 	$("nav").addClass("navbar-fixed-top");
		// }

	});
	
	// if ($(window).innerWidth() > 767) {
	// 	$("nav").removeClass("navbar-fixed-top");
	// } else if ($(window).innerWidth() < 767) {
	// 	$("nav").addClass("navbar-fixed-top");
	// }

	$(document).ready(function() {
		checkMobile();
		setInfographic();

		$(".hamburger").click(function() {
		  	$("#mobile-nav").slideToggle("fast");
		});
		
		$("#footer-links h5").click(function() {
	  		$(this).siblings(".hideable").slideToggle("fast");
		});

		var doubleSelector = null;
		$(".nav li").each(function() {

			$(this).on('click', function(e) {

				var element = $(this);

				if($(this).has(".subnav").length) {
					if((doubleSelector == null || !doubleSelector.is($(this))) && isMobile) {
						e.preventDefault();
						doubleSelector = $(this);
						slideSubNav( $(this), true );
					} else {
						slideSubNav($(this), false);
						doubleSelector = null;
					}
				}
			});

		});

		// $(".video-content").css( 'display', 'block' );
	
		

		//footer copyright date injection
		document.getElementById("copy-date").innerHTML = new Date().getFullYear();

	});

	function slideSubNav(element, slidedown) {
		var icon = element.find(".btn-open-close .icon");
		if(slidedown) {
			element.find(".subnav").slideDown();
			icon.removeClass("icon-before-plus");
			icon.addClass("icon-before-minus");
			
		} else {
			element.find(".subnav").slideUp();
			icon.addClass("icon-before-plus");
			icon.removeClass("icon-before-minus");
		}
	}

	function closeMobileNav() {
		
		$(".nav li").each(function() {

			var icon = $(this).find(".btn-open-close .icon");
			var subnav = $(this).find(".subnav");
			icon.addClass("icon-before-plus");
			icon.removeClass("icon-before-minus");
			subnav.hide();
			subnav.removeAttr("style");
		});

	}

	function setInfographic() {
		
		var infoEl = $(".dc-infographic img");

		if(isMobile){
			infoEl.attr('src', '/resources/img/infographic_mobile.png');
		} else {
			infoEl.attr('src', '/resources/img/infographic_desktop.png');
		}
	}

	function FormController() {

		var frameForm = $('.form-controller');
		var form =  $('.form-controller').contents().find('#request-form');
		var responseMessage = $('.form-response-message');
		var formGroup = form.find('.form-group');

		var submitted = false;

		var button = form.find('.btn-submit');

		button.on('click', function(e) {
			e.preventDefault();
			onSubmit();
		});

		frameForm.on('load', function() {
			if (submitted) {
		      onFormSuccess(null);
		    }
		});
		
		function onSubmit() {	
			if(!validateForm()) return;
			parseTechForm();
			submitted = true;
			form.submit();
		}

		function onFormSuccess(response) {
	      
		    // var formContent = $( '.form-content' );
		    // var formError = $( '.form-error' );
		    // formContent.animate( { height : '50px', duration : 0.1 } );
		    // formContent.html( 'SUCCESS' );
		    // formError.slideUp();
		    frameForm.slideUp();
		    responseMessage.slideDown();
		      
		}

		function onFormFailure(response) {
			var formError = $('.form-error');
			formError.slideDown();
		}

		function validateForm() {

			var validated = true;

			formGroup.children('input').each(function() {
				var val = $(this).val();
				if(val === undefined || val === '') {
					validated = false;
				} else {
					if( $(this).attr('name') === 'email' ) {
						// EMAIL CHECK
						var emailPattern = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
						validated = emailPattern.test( $(this).val() );
					} else if ($(this).attr('name') === 'phone') {
						// PHONE CHECK
						var phonePattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
						validated = phonePattern.test( $(this).val() );
					}
				}

				if (!validated) {
					$(this).css( 'border', '2px solid red' );
				} else {
					$(this).css( 'border', 'initial' );
				}
			});

			formGroup.children( 'select' ).each( function() {
				var val = $(this).val();
				if( val === undefined || val === '' ) {
					$(this).css( 'border', '2px solid red' );
					validated = false;
				}

				if( !validated ) {
					$(this).css( 'border', '2px solid red' );
				} else {
					$(this).css( 'border', 'initial' );
				}
			});

			return validated;

		}

		function parseTechForm() {
			if(form.attr('name') == 'tech-form') {
				var tf = form.find( '#00NE0000006OF6B' );
				if(tf.length) {
					var newVal = tf.attr('placeholder') + ". " + tf.val();
					tf.attr('value', newVal);
					tf.html(newVal);
				}

			}

		}

	}
	
}